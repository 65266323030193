import {
  ApiError,
  GenerationParams,
  GenerationResult,
  GetFirstFrameEmbeddingResp,
  InpaintingParams,
  JobStatus,
  // Pagination,
  // PaginationQuery,
  RepaintingParams,
  // Suggestion,
  Creation,
  Segment,
  SegmentQuery,
  ExtendGenerationParams,
  UpscaleGenerationParams,
} from '@/types'
import { rest } from '@/utils'
import {
  processInpaintingVideo,
  samVitImageEmbedding,
} from './replicate.service'

// const getJobs = async (
//   params: PaginationQuery<{}>,
// ): Promise<Pagination<Creation>> => {
//   const res = await rest.get('/v1/jobs', { params })
//   return res.data
// }

export const getJobsSegment = async (
  params: SegmentQuery<{}>,
): Promise<Segment<Creation>> => {
  const res = await rest.get('/v1/jobs', { params })
  return res.data
}

export const getJobStatus = async (id: string): Promise<JobStatus> => {
  const res = await rest.get(`/v1/jobs/${id}/status`)
  return res.data
}

export const generate = async (
  data: GenerationParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/generation', data)
  return res.data
}

export const generateBB8 = async (
  data: GenerationParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/generation-v2', data)
  return res.data
}

export const generateAfc = async (
  data: GenerationParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/afc', data)
  return res.data
}

export const generateImg = async (
  data: GenerationParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/text2image', data)
  return res.data
}

export const repaint = async (
  data: RepaintingParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/repainting', data)
  return res.data
}

export const inpaint = async (
  data: InpaintingParams,
): Promise<GenerationResult> => {
  const res = await rest.post('/v1/jobs/inpainting', data)
  return res.data
}

export const retry = async (id: string): Promise<GenerationResult> => {
  const res = await rest.post(`/v1/jobs/${id}/retry`)
  return res.data
}

export const feedback = async (generationId: string, like: number) => {
  const body =
    like === 1
      ? { is_like: true, is_dislike: false }
      : like === -1
        ? { is_like: false, is_dislike: true }
        : { is_like: false, is_dislike: false }
  const res = await rest.post('/v1/creation/commits/like', {
    creation_id: generationId,
    ...body,
  })
  return res.data
}

// export const getRecommendedStyles = async (): Promise<
//   Record<string, Suggestion[]>
// > => {
//   const res = await rest.get('/v1/jobs/styles')
//   return res.data
// }

export const regenerate = async (
  generationId: string,
  data: { is_public: boolean },
  version = 'v1',
) => {
  const res = await rest.post(`/v1/jobs/${generationId}/regenerate`, data)
  return res.data
}

export const upscale = async (data: UpscaleGenerationParams) => {
  const res = await rest.post('/v1/jobs/upscale', data)
  return res.data
}

export const extend = async (data: ExtendGenerationParams) => {
  const res = await rest.post('/v1/jobs/extend', data)
  return res.data
}

export const varyPrompt = async (
  generationId: string,
  data: { prompt: string; negative_prompt?: string; is_public: boolean },
  version: string = 'v1',
) => {
  const res = await rest.post(`/v1/jobs/${generationId}/vary-prompt`, data)
  return res.data
}

export const deleteJob = async (generationId: string) => {
  const res = await rest.delete(`/v1/jobs/${generationId}`)
  return res.data
}

export const getFirstFrameEmbedding = async (
  fileId: string,
): Promise<{ data: GetFirstFrameEmbeddingResp | null; error?: ApiError }> => {
  if (!String(fileId)?.endsWith('.mp4')) {
    throw new Error('Invalid file type')
  }

  try {
    const { first_frame_embedding_url, output_video_url } =
      (await processInpaintingVideo(fileId)) ?? {}

    if (first_frame_embedding_url && output_video_url) {
      // get first frame embedding
      const imageEmbeddingRes = await samVitImageEmbedding(
        first_frame_embedding_url,
      )
      return {
        data: {
          first_frame_url: first_frame_embedding_url,
          embedding_url: imageEmbeddingRes,
          converted_video: output_video_url,
        },
      }
    } else {
      return {
        data: null,
        error: {
          code: 500,
          message: 'Failed to process video',
          details: [],
          days_to_expire: 0,
        },
      }
    }
  } catch (error: any) {
    const res: ApiError = {
      code: error?.code ?? 500,
      message: error?.message ?? '',
      details: [error],
      days_to_expire: 0,
    }
    return {
      data: null,
      error: res,
    }
  }
}

export const putPublicStateOfCreationById = async (
  generationId: string,
  isPublic: boolean,
) => {
  const res = await rest.put(`/v1/creation/${generationId}/visibility`, {
    is_public: isPublic,
  })
  return res
}
