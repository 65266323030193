import {
  ApiError,
  Creation,
  // CreationQuery,
  Pagination,
  PaginationQuery,
  Segment,
  SegmentQuery,
} from '@/types'
import { rest } from '@/utils'

// const getCreations = async (
//   params?: CreationQuery,
// ): Promise<Creation[]> => {
//   const res = await rest.get('/v1/creations', { params })
//   return res.data
// }

export const getCreation = async (
  id: string,
): Promise<{ data?: Creation | null; error?: ApiError }> => {
  const res = await rest.get(`/v1/creation/${id}`)
  return res
}

export const getOutput = async (
  id: string,
): Promise<{ data?: Creation | null; error?: ApiError }> => {
  const res = await rest.get(`/v2/creation/detail/${id}`)
  return res
}

export const getTopCreations = async (
  params: PaginationQuery<{}>,
): Promise<Pagination<Creation>> => {
  const res = await rest.get('/v2/creations/top', { params })
  return res.data
}

// const getTopCreationsSegment = async (
//   params: SegmentQuery<{}>,
// ): Promise<Segment<Creation>> => {
//   const res = await rest.get('/v2/creations/top', { params })
//   return res.data
// }

export const getTagCreations = async (
  params: PaginationQuery<{ tag: string }>,
): Promise<Pagination<Creation>> => {
  const { tag, ...others } = params ?? {}
  const res = await rest.get(`/v1/creations/tag/${tag}`, { params: others })
  return res.data
}

// const getTagCreationsSegment = async (
//   params: SegmentQuery<{ tag: string }>,
// ): Promise<Segment<Creation>> => {
//   const { tag, ...others } = params ?? {}
//   const res = await rest.get(`/v1/creations/tag/${tag}`, { params: others })
//   return res.data
// }

export const postLikeStateToCreationById = async (data: {
  creation_id: string
  is_like: boolean
}): Promise<void> => {
  await rest.post('/v1/creation/commits/like', data)
}

export const postCollectStateToCreationById = async (
  data: {
    creation_id?: string
    output_id?: string
    is_collect: boolean
  },
  version = 'v1',
): Promise<void> => {
  await rest.post(`/${version}/creation/commits/collect`, data)
}

// const getUserCreations = async (
//   params: SegmentQuery<{ user_id: string }>,
// ): Promise<Creation[]> => {
//   const { user_id, ...others } = params
//   const res = await rest.get(`/v1/user/${user_id}/creations`, {
//     params: others,
//   })
//   return res.data
// }

export const getUserCreationsSegment = async (
  params: SegmentQuery<{ user_id: string }>,
): Promise<Segment<Creation>> => {
  const { user_id, ...others } = params
  const res = await rest.get(`/v1/user/${user_id}/creations`, {
    params: others,
  })
  return res.data
}

export const postWatermarkFreeUrl = async (
  creationId: string,
): Promise<{ url: string; expire_time: string }> => {
  const res = await rest.post(`/v1/creation/${creationId}/watermark-free-url`)
  return res.data
}
